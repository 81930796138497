import { EBizCode } from "@/api/station/dji/enums";
import EventBus from '@/station/event-bus'
import store from "@/store";

export function messageHandler(payload){
  if (!payload) {
    return
  }

  // console.log('messageHandler: ', store.state.station)
  // console.log('messageHandler: ', payload)
  switch (payload.biz_code) {
    case EBizCode.GatewayOsd : {
      // console.info('GatewayOsd: ', payload)
      break
    }

    case EBizCode.DockOsd: {
      store.dispatch("station/SET_DEVICE_DOCK_INFO", payload.data);
      // console.info('DockOsd: ',  payload.data)
      break
    }
    case EBizCode.DeviceOsd: {
      store.dispatch("station/SET_DEVICE_DRONE_INFO", payload.data);
      // console.info('DroneOsd: ',  payload.data)
      break
    }
    case EBizCode.DeviceOnline: {
      // console.info('dock online: ', payload)
      break
    }
    case EBizCode.DeviceOffline: {
      // console.info('device offline: ', payload)
      store.dispatch("station/DEVICE_DRONE_OFFLINE", payload.data);
      break
    }
    case EBizCode.DeviceReboot:
    case EBizCode.DroneOpen:
    case EBizCode.DroneClose:
    case EBizCode.CoverOpen:
    case EBizCode.CoverClose:
    case EBizCode.PutterOpen:
    case EBizCode.PutterClose:
    case EBizCode.ChargeOpen:
    case EBizCode.ChargeClose:
    case EBizCode.DeviceFormat:
    case EBizCode.DroneFormat: {
      store.dispatch('station/SET_DEVICES_CMD_EXECUTE_INFO', {
        biz_code: payload.biz_code,
        timestamp: payload.timestamp,
        ...payload.data,
      })
      break
    }
    case EBizCode.FlightTaskProgress: {
      store.dispatch('station/SET_DEVICES_MISSION_STATUS_INFO', {
        biz_code: payload.biz_code,
        timestamp: payload.timestamp,
        ...payload.data,
      })
      break
  }
    case EBizCode.ControlSourceChange:
    case EBizCode.FlyToPointProgress:
    case EBizCode.TakeoffToPointProgress:
    case EBizCode.JoystickInvalidNotify:
    case EBizCode.DrcStatusNotify: {
      EventBus.emit('droneControlWs', payload)
      break
    }
    default:
      break
  }


}
