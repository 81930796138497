import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function fetchStationMissionJob(stationDeviceId) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/mission/job/station/${stationDeviceId}`, {headers, params: null});
}

export function fetchStationMissionJobStatus(stationDeviceId) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/mission/job/status/station/${stationDeviceId}`, {headers, params: null});
}
