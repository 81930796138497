import i18n from "@/lang";

export function popOpen(url, target, features) {
    let token = localStorage.getItem("token");
    let accountId = localStorage.getItem("accountId");

    if(token == undefined || token == "") {
        alert(i18n.t("856"));
        location.href = `/${accountId}/login`;
        return;
    }
    window.open( url, target, features);
}
export function newTab(url, target) {
    popOpen( url, target,"")
}

export function playerPopOpen(type, id) {
    let accountId = localStorage.getItem("accountId");

    if(accountId == undefined || accountId == "") {
        alert(i18n.t("856"));
        return;
    }
    var url = ""
    var target = ""
    var features = ""


    if (type == "live") {
        url = `/${accountId}/livePlay?channelId=${id}`;
        target = `homeLive${id}`;
        features = "width="+screen.availWidth+",height="+screen.availHeight+",resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,fullscreen=yes'";
    } else {
        url = `/${accountId}/vodPlay?contentsId=${id}`;
        target = `homeVod${id}`;
        features = "width="+screen.availWidth+",height="+screen.availHeight+",resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,fullscreen=yes'";
    }

    popOpen( url, target, features);
}

export function playerPopOpen2(type, id, channelIndex) { //channelIndex 넘겨주는거 때문.

    console.log("playerPopOpen2", type, id, channelIndex)

    let accountId = localStorage.getItem("accountId");
    console.log("accountId, ",accountId)

    if(accountId == undefined || accountId == "") {
        alert(i18n.t("856"));
        return;
    }
    var url = ""
    var target = ""
    var features = ""


    if (type == "live") {
        url = `/${accountId}/livePlay?channelId=${id}&channelIndex=${channelIndex}`;
        target = `homeLive${id}`;
        features = "width="+screen.availWidth+",height="+screen.availHeight+",resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,fullscreen=yes'";
    } else {
        url = `/${accountId}/vodPlay?contentsId=${id}`;
        target = `homeVod${id}`;
        features = "width="+screen.availWidth+",height="+screen.availHeight+",resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,fullscreen=yes'";
    }

    popOpen( url, target, features);
}

// station popup

export function stationPopup(type, stationDeviceId){
    let accountId = localStorage.getItem("accountId");

    if(accountId == undefined || accountId == "") {
        alert(i18n.t("856"));
        return;
    }

    if(stationDeviceId == undefined || stationDeviceId == "") {
        alert(i18n.t("856"));
        return;
    }

    var url = ""
    var target = ""
    var features = ""

    if(type == "stationControl"){
        url = `/${accountId}/stationControl/${stationDeviceId}`;
        target =  `stationControl_${stationDeviceId}`;
        features = "width="+screen.availWidth+",height="+screen.availHeight+",resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,fullscreen=yes'";
        //this.$router.push('stationControl')
    }

    popOpen(url,target,features);
}
